import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Slider from '../../components/slider';
import { Markdown } from '../../components/utils';
import { SM } from '../../config/breakpoints';
import { useHighlightByUuid } from '../../hooks/use-highlights';

import Icons from './icons';
import styles from './highlight-gallery.module.scss';

const HighlightGallery = ({ uuid, buildUrl }) => {
	const { name, images, icons, text } = useHighlightByUuid(uuid) || {};
	const [isClient, setIsClient] = useState(false);
	const isMinSM = useMediaQuery({ minWidth: SM });

	const onClick = event => {
		const {
			currentTarget: { href }
		} = event;

		event.preventDefault();

		const { pathname, search, hash } = new URL(href);
		navigate(`${pathname}${search}${hash}`);
	};

	// rehydrate media queries on mounting because of SSR!
	useEffect(() => {
		setIsClient(true);
	}, []);

	const slidesToShow = (isClient && isMinSM && 3) || 2;

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>{name}</h2>
			<Slider inlineArrows={true} className={styles.slider} slidesToShow={slidesToShow}>
				{Array.isArray(images) &&
					images.map(({ id, childImageSharp: { fluid } }) => (
						<a
							key={id}
							onClick={onClick}
							className={styles.link}
							href={buildUrl(uuid, id)}>
							<Img fluid={fluid} className={styles.image} />
						</a>
					))}
			</Slider>

			<div className={styles.icons}>
				<Icons icons={icons} />
			</div>

			{text && (
				<div className={styles.text}>
					<Markdown content={text} />
				</div>
			)}
		</div>
	);
};

HighlightGallery.defaultProps = {
	uuid: null,
	buildUrl: () => ''
};

HighlightGallery.propTypes = {
	uuid: PropTypes.string.isRequired,
	buildUrl: PropTypes.func.isRequired
};

export default HighlightGallery;
