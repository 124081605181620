import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { CloseIcon, ArrowLeftIcon, ArrowRightIcon } from './icons';

import styles from './lightbox.module.scss';

const LightBox = ({ image, caption, hasPrev, onPrev, hasNext, onNext, onClose }) => {
	const [style, setStyle] = useState({
		height: '100%',
		width: '100%'
	});

	const ref = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			const { current: content } = ref;
			if (content === null) {
				return;
			}

			const { offsetHeight, offsetWidth } = content;

			const {
				childImageSharp: {
					fluid: { aspectRatio, presentationWidth, presentationHeight }
				}
			} = image;

			const style =
				aspectRatio > 1
					? {
							// landscape photos
							maxWidth: Math.min(offsetWidth, presentationWidth),
							maxHeight: Math.min(
								offsetWidth / aspectRatio,
								offsetHeight,
								presentationHeight
							)
					  }
					: {
							// portrait photos
							maxWidth: Math.min(
								offsetHeight * aspectRatio,
								offsetWidth,
								presentationWidth
							),
							maxHeight: Math.min(offsetHeight, presentationHeight)
					  };

			setStyle(style);
		};

		window.addEventListener('resize', handleResize, false);
		handleResize(); // trigger after initial rendering once

		return () => {
			window.addEventListener('resize', handleResize, false);
		};
	}, [image]);

	useEffect(() => {
		const handleKeydown = event => {
			switch (event.keyCode) {
				case 27: {
					onClose(event);
					break;
				}

				case 37: {
					hasPrev && onPrev(event);
					break;
				}

				case 39: {
					hasNext && onNext(event);
					break;
				}

				default:
			}
		};

		document.addEventListener('keydown', handleKeydown, false);

		return () => {
			document.removeEventListener('keydown', handleKeydown, false);
		};
	}, [image]);

	return (
		<Fragment>
			<Helmet>
				<body className={styles.bodyLightboxOpen} />
			</Helmet>
			<div role="button" tabIndex="-1" onClick={onClose} className={styles.modal}>
				<button onClick={onClose} className={styles.close}>
					<CloseIcon className={styles.icon} />
				</button>

				{hasPrev && (
					<button
						onClick={event => {
							event.stopPropagation();
							// event.preventDefault();
							onPrev();
						}}
						className={styles.prev}>
						<ArrowLeftIcon className={styles.icon} />
					</button>
				)}

				<div ref={ref} className={styles.wrapper}>
					<Img
						style={style}
						className={styles.image}
						fluid={image.childImageSharp.fluid}
					/>

					{caption && <div className={styles.caption}>{caption}</div>}
				</div>

				{hasNext && (
					<button
						onClick={event => {
							event.stopPropagation();
							// event.preventDefault();
							onNext();
						}}
						className={styles.next}>
						<ArrowRightIcon className={styles.icon} />
					</button>
				)}
			</div>
		</Fragment>
	);
};

LightBox.defaultProps = {
	image: null,
	caption: null,
	hasNext: false,
	hasPrev: false,
	onNext: () => {},
	onPrev: () => {},
	onClose: () => {}
};

LightBox.propTypes = {
	image: PropTypes.shape({
		childImageSharp: PropTypes.shape({
			fluid: PropTypes.shape({
				presentationHeight: PropTypes.number.isRequired,
				presentationWidth: PropTypes.number.isRequired,
				aspectRatio: PropTypes.number.isRequired,
				base64: PropTypes.string.isRequired,
				srcSet: PropTypes.string.isRequired,
				sizes: PropTypes.string.isRequired,
				src: PropTypes.string.isRequired
			})
		})
	}),
	caption: PropTypes.string,
	onNext: PropTypes.func.isRequired,
	onPrev: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	hasNext: PropTypes.bool.isRequired,
	hasPrev: PropTypes.bool.isRequired
};

export default LightBox;
