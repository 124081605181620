import React from 'react';
import { graphql, navigate } from 'gatsby';

import { Page } from '../components/layout';
import Lightbox from '../components/lightbox';
import { referrerIsInternal, getPagePath, queryString } from '../utils/url';

import {
  useHighlightByUuid,
  useHighlightBySlug,
  useHighlightImageByUuid
} from '../hooks/use-highlights';
import { useOfferByUuid, useOfferFeaturedByUuid } from '../hooks/use-offers';

import Teaser from './offer/teaser';
import Customers from './shared/customers';
import HighlightModal from './offer/highlight-modal';
import HighlightDefault from './offer/highlight-default';
import HighlightGallery from './offer/highlight-gallery';
import HighlightYouTube from './shared/highlight-youtube';

const PARAM_IMG = 'i';
const PARAM_GALLERY = 'g';
const PARAM_HIGHLIGHT = 'h';

import styles from './offer.module.scss';

const Offer = ({
  data: {
    child: {
      frontmatter: { uuid }
    },
    parent: { html }
  }
}) => {
  const { slug, highlights, galleries, videos } = useOfferByUuid(uuid) || {};
  const highlight = useHighlightBySlug(queryString(PARAM_HIGHLIGHT));
  const gallery = useHighlightByUuid(queryString(PARAM_GALLERY));
  const image = useHighlightImageByUuid(queryString(PARAM_IMG));
  const featuredMap = useOfferFeaturedByUuid(uuid) || {};

  // get image meta about next/prev image from image's object by gallery
  const imageMeta = image && gallery && image[gallery.uuid][image.id];

  const buildHighlightUrl = slug => `${getPagePath()}?${PARAM_HIGHLIGHT}=${slug}`;
  const buildGalleryUrl = (galleryId, imageId) =>
    `${getPagePath()}?${PARAM_IMG}=${imageId}&${PARAM_GALLERY}=${galleryId}`;
  const goToImage = image => navigate(buildGalleryUrl(gallery.uuid, image.id));
  const onPrev = () => goToImage(imageMeta.prevImage);
  const onNext = () => goToImage(imageMeta.nextImage);
  const onClose = () => {
    if (referrerIsInternal()) {
      window.history.back();
      return;
    }

    navigate(getPagePath());
  };

  return (
    <Page slug={slug} teaser={<Teaser content={html} />}>
      {Array.isArray(highlights) &&
        highlights.map(({ title, text }, index) => (
          <div key={index} className={styles.highlight}>
            <HighlightDefault
              text={text}
              title={title}
              buildUrl={buildHighlightUrl}
              featured={featuredMap[index]}
            />
          </div>
        ))}

      {Array.isArray(galleries) &&
        galleries.map((uuid, index) => (
          <div key={index} className={styles.highlight}>
            <HighlightGallery uuid={uuid} buildUrl={buildGalleryUrl} />
          </div>
        ))}

      {Array.isArray(videos) &&
        videos.map((uuid, index) => (
          <div key={index} className={styles.highlight}>
            <HighlightYouTube uuid={uuid} />
          </div>
        ))}

      <Customers />

      {highlight && <HighlightModal uuid={highlight.uuid} onClose={onClose} />}

      {gallery && image && (
        <Lightbox
          image={image}
          onPrev={onPrev}
          onNext={onNext}
          onClose={onClose}
          hasPrev={!!imageMeta.prevImage}
          hasNext={!!imageMeta.nextImage}
        />
      )}
    </Page>
  );
};

export const offerQuery = graphql`
  query offerQuery($remarkId: String!, $locale: String!) {
    parent: markdownRemark(fields: { key: { eq: "offers" }, locale: { eq: $locale } }) {
      html
    }

    child: markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        uuid
      }
    }
  }
`;

export default Offer;
