import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Slider from '../../components/slider';
import { Markdown } from '../../components/utils';
import { SM } from '../../config/breakpoints';

import styles from './highlight-default.module.scss';

const HighlightDefault = ({ title, text, featured, buildUrl }) => {
	const [isClient, setIsClient] = useState(false);
	const isMinSM = useMediaQuery({ minWidth: SM });

	const onClick = event => {
		const {
			currentTarget: { href }
		} = event;

		event.preventDefault();

		const { pathname, search, hash } = new URL(href);
		navigate(`${pathname}${search}${hash}`);
	};

	// rehydrate media queries on mounting because of SSR!
	useEffect(() => {
		setIsClient(true);
	}, []);

	const slidesToShow = (isClient && isMinSM && 3) || 2;

	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>{title}</h2>
			<Slider inlineArrows={true} className={styles.slider} slidesToShow={slidesToShow}>
				{featured.map(({ slug, name, image: { childImageSharp: { fluid } } }, index) => (
					<a key={index} onClick={onClick} className={styles.link} href={buildUrl(slug)}>
						<div className={styles.label}>{name}</div>
						<Img fluid={fluid} className={styles.image} />
					</a>
				))}
			</Slider>
			{text && (
				<div className={styles.text}>
					<Markdown content={text} />
				</div>
			)}
		</div>
	);
};

HighlightDefault.defaultProps = {
	text: null,
	title: null,
	featured: [],
	buildUrl: () => ''
};

HighlightDefault.propTypes = {
	title: PropTypes.string.isRequired,
	featured: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.shape({
				childImageSharp: PropTypes.shape({
					fluid: PropTypes.object.isRequired
				})
			}).isRequired,
			slug: PropTypes.string.isRequired
		})
	).isRequired,
	text: PropTypes.string,
	buildUrl: PropTypes.func.isRequired
};

export default HighlightDefault;
